import '../css/app.css';
import 'lazysizes';
import Alpine from 'alpinejs';
import Splide from '@splidejs/splide';
import baguetteBox from 'baguettebox.js';

import intersect from '@alpinejs/intersect';

Alpine.plugin(intersect);

Alpine.data('hero', () => ({
  init() {
    this.setViewHeight();
    window.addEventListener('resize', () => {
      this.setViewHeight();
    });
  },
  setViewHeight: function () {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  },
}));

Alpine.start();

// Splide
document.addEventListener('DOMContentLoaded', function () {
  baguetteBox.run('.baguette', {
    noScrollbars: true,
  });

  const cards = document.getElementsByClassName('splide-cards');
  for (let i = 0; i < cards.length; i++) {
    new Splide(cards[i], {
      lazyLoad: 'nearby',
      perPage: 4,
      gap: 20,
      pagination: false,
      arrows: true,
      breakpoints: {
        1280: {
          perPage: 3,
          arrows: false,
          pagination: true,
        },
        960: {
          perPage: 2,
          pagination: true,
          arrows: false,
        },
        600: {
          perPage: 1,
          pagination: true,
          arrows: false,
        },
      },
    }).mount();
  }

  const teaser = document.getElementsByClassName('splide-teaser');
  for (let i = 0; i < teaser.length; i++) {
    new Splide(teaser[i], {
      lazyLoad: 'nearby',
      perPage: 1,
      gap: 0,
      pagination: false,
      arrows: true,
      breakpoints: {
        1280: {
          arrows: false,
          pagination: true,
        },
        960: {
          pagination: true,
          arrows: false,
        },
        600: {
          pagination: true,
          arrows: false,
        },
      },
    }).mount();
  }

  const galleries = document.getElementsByClassName('splide-gallery');
  for (let i = 0; i < galleries.length; i++) {
    new Splide(galleries[i], {
      lazyLoad: 'nearby',
      perPage: 4,
      gap: 20,
      pagination: false,
      arrows: true,
      breakpoints: {
        1280: {
          perPage: 3,
          arrows: false,
          pagination: true,
        },
        960: {
          perPage: 2,
          pagination: true,
          arrows: false,
        },
        600: {
          perPage: 1,
          pagination: true,
          arrows: false,
        },
      },
    }).mount();
  }
});
